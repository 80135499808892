import React from 'react'
import styled from 'styled-components'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <Hero className="hero" />
    <SEO title="404" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

export default NotFoundPage

const Hero = styled.div`
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.25%;
  background: red;
  @media (min-width: 1025px) {
    padding-top: 600px;
  }
`
